<script>
import { ArrowUpIcon, MapPinIcon, PlusIcon, MoreHorizontalIcon } from "vue-feather-icons";



//import PostHighlightLess from "./PostHighlightLess";

import store from "@/store/store";
/**
 * Index-hosting component
 */
export default {

  components: {

    //PostHighlightLess,

    ArrowUpIcon,
    MapPinIcon,
    PlusIcon,
    MoreHorizontalIcon,

  },
  data() {
    return {
      hover: false,

      additionalParamentersMenu: false,

      search_line_string: '',


      limit_Options: [
        { id: 1, value: '25' },
        { id: 2, value: '50' },
        { id: 3, value: '100' },
        { id: 4, value: '500' },
      ],
      limit_Selected: { id: 1, value: '25' },

      certainty_Options: [
        { id: 1, value: '0.1' },
        { id: 2, value: '0.2' },
        { id: 3, value: '0.3' },
        { id: 4, value: '0.4' },
        { id: 5, value: '0.5' },
        { id: 6, value: '0.6' },
        { id: 7, value: '0.7' },
        { id: 8, value: '0.8' },
        { id: 9, value: '0.9' },
        { id: 10, value: '1' },

      ],
      certainty_Selected: { id: 5, value: '0.5' },


      additionalSearchLines: [1, 2],
      advancedSearchSwitch: true,





      input_advanced_search: {
        user_id: 1,
        searchParameters: [],
        searchFlags: {},
        page: 1,
        resumes_per_page: 10,
        hash: '',
      },

      searchLine: '',

      search_parameters: [
        {
          leftParethesis: false,
          field: { name: 'Resume headline', id: 1, type: 'String' },
          relationship: { name: 'contains', id: 2 },
          value: '',
          orButton: false,
          andButton: false,
          rightParethesis: false,
          id: 0
        }
      ],

      search_parameters_arr: [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }],

      search_parameters_obj: {
        message: '',
        limit: '25',
        certainty: '0.5',
      },

      search_parameters_additional: [],

      search_flags: {
        switchEmail: false,
        switchContact: false,

        switchRemote: false,
        switchReadyToMove: false,

        switchHighlighView: false,
        switchResumeView: false,
        switchSaved: false,
      },

      setSQL_ids: {
        ids_arr: [1, 2],
        save_search_id: 1,
        user_id: 2,
      },

      savedSearch: { name: 'Базовый', id: 0, type_id: 0 },

      resumeIds: [],
      resumes: [],

      total_resume: 5836,

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],

      latestSearches: [],


      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id: 0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },

      totalSearchResults: 1,
      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr: [],

      filters: {
        industryFilter: false,
        gradeFilter: false,
        lastActiveFilter: false,
        jobTypeFilter: false,
        experianceFilter: false,
      },

      posts: [],

    };
  },
  watch: {
    certainty_Selected(val) {
      this.search_parameters_obj.certainty = val.value;
    },
    limit_Selected(val) {
      this.search_parameters_obj.limit = val.value;
    },
    search_line_string(val) {

      this.search_parameters_obj.message = val;

    }
  },
  mounted() {


  },

  methods: {



    nullSearch() {
      this.search_line_string = '';
      this.andOrFilter_Selected = { id: 1, value: 'Все слова' };
      this.whereToSearch_Selected = { id: 1, value: 'Везде' };
      this.search_parameters_arr = [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }];
      this.search_parameters_additional = [];
    },


    addRemoveLine(x) {
      if (x === -1) {
        this.search_parameters_additional.push(this.search_parameters_obj);
        this.search_parameters_arr.push(this.search_parameters_obj);
      } else {
        this.search_parameters_additional.splice(x, 1);
        this.search_parameters_arr.splice(x + 1, 1);
      }
      console.log('inside addRemoveLine:')
      console.log(this.search_parameters_arr);
    },

    updateParameters(e) {
      console.log('inside updateParameters')
      console.log(e);
      this.addRemoveLine(e);
    },

    update_search_parameters_arr(e) {
      console.log('inside update_search_parameters_arr')
      //console.log(e);

      this.search_parameters_arr[e.index + 1].search_line_string = e.search_line_string;
      this.search_parameters_arr[e.index + 1].andOrFilter_id = e.andOrFilter_id;
      this.search_parameters_arr[e.index + 1].whereToSearchFilter_id = e.whereToSearchFilter_id;
      //this.search_parameters_additional = e.splice(0, 1);
      console.log(this.search_parameters_arr);
    },





    loadNews() {

      console.log('inside loadNews')
      this.posts = [];

      this.search_parameters_obj.message = this.search_line_string;



      this.$vs.loading({
        container: '#div-with-loading',
        scale: 1,
        opacity: 0.1,
      });

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/weaviate/search-news-like',
        data: this.search_parameters_obj,
      }).then((res) => {



        if (res.data.length) {
          for (var k = 0; k < res.data.length; k++) {
            this.posts.push(res.data[k]);
          }

        }
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");

      }).catch((error) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      });



    },

    paginationUtil(x) {
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active + 1, this.page.max);
      this.page.down = Math.max(this.page.active - 1, this.page.min);
      if (this.page.active > this.page.min) {
        this.page_arr.push({ page: '' + this.page.min, active: 'notactive' });
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active - this.page.min >= this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({ page: '...', active: 'notactive' });
        this.page_arr.push({ page: '' + this.page.down, active: 'notactive' });
        if (this.page.active < this.page.max) {
          this.page_arr.push({ page: '' + this.page.active, active: 'active' });
        }
      } else {

        for (var i = 2; i < this.page.active; i++) {
          this.page_arr.push({ page: '' + i, active: 'notactive' });
        }
        if (this.page.active < this.page.max) {
          this.page_arr.push({ page: '' + this.page.active, active: 'active' });
        }

      }
      if (this.page.max - this.page.active >= this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({ page: '' + this.page.up, active: 'notactive' });
        this.page_arr.push({ page: '...', active: 'notactive' });
        this.page_arr.push({ page: '' + this.page.max, active: 'notactive' });

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max === i & this.page.active === this.page.max) {
            this.page_arr.push({ page: '' + i, active: 'active' });
          } else {
            this.page_arr.push({ page: '' + i, active: 'notactive' });
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
    },



    PaginationStep(x) {

      if (x === -1) {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + this.page.down });
        window.open(route.href, '_self');
      } else if (x === -2) {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + this.page.up });
        window.open(route.href, '_self');
      } else {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + x });
        window.open(route.href, '_self');
      }

    },




  },


};
</script>

<template>
  <div>
    <!-- Search Start -->

    <div class="container mt-5 vs-con-loading__container" id="div-with-loading">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <form class="p-4 shadow bg-white rounded">
            <div class="d-flex justify-content-between">
              <h4 class="mb-3">Поиск по заголовкам новостей</h4>

            </div>
            <div class="row">
              <div class="col-10 ">


                <div class="input-group-append form-group">


                  <input name="name" id="name" type="text" class="form-control rounded-left col-6"
                    placeholder="Enter keywords :" v-model="search_line_string" />

                  <select class="form-control custom-select rounded-0 col-2" v-model="certainty_Selected">
                    <option v-bind:value="x" v-for="x in certainty_Options">{{ x.value }}</option>
                  </select>
                  <select class="form-control custom-select rounded-0 col-2" v-model="limit_Selected">
                    <option v-bind:value="x" v-for="x in limit_Options">{{ x.value }}</option>
                  </select>

                  <input class="searchbtn btn btn-primary btn-block " value="Поиск" @click="loadNews" />

                </div>

              </div>


            </div>


          </form>

        </div>

      </div>

    </div>






    <div>
      <!-- <div class="row m-4">
        <div class="col-lg-3 col-md-6 col-12">

        </div>
        <div class="col-lg-8 col-md-6 col-12">
           <h3> {{ new Intl.NumberFormat().format(totalSearchResults).replace(',', ' ') }} jobs found </h3>
          </div>
      </div>-->
      <div class="row ml-4 mr-4">

        <div class="col-1"> <b>#.</b> </div>
        <div class="col-1"> <b>Степень соответствия</b></div>
        <div class="col-8"><b> Заголовок новости </b></div>
      </div>


      <div class="row ml-4 mr-4" :post="post" :index="ix" v-for="(post, ix) in posts">

        <div class="col-1">{{ ix + 1 }}.</div>
        <div class="col-1">{{ post.certainty }}%</div>
        <div class="col-8">{{ post.value }}</div>

      </div>


    </div>



  </div>
</template>


<style lang="scss">
.active {
  cursor: pointer;
}
</style>